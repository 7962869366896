import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import BlogWrapper from "../../../components/article/BlogWrapper";
import StateBusinessBankBlogContent from "../../../components/state-business-bank/StateBusinessBankAccountRequirementsBlogContent";
import { Link } from "gatsby";

// NOTE: for apostrophes or single quotes in the title to render, please use an ASCII single quote and wrap the string in backticks.
const SEO = {
  MetaTitle: `What's Needed to Open a Business Bank Account in California`,
  MetaDescription:
    "Learn what's needed to open a business bank account in California including key documents needed. Open a California business bank account today with WaFd Bank.",
  Schema: {
    "@id": "https://www.wafdbank.com/blog/small-business/california-business-bank-account-requirements",
    "@type": "Article",
    image: "https://www.wafdbank.com/images/adsearch/og-open-business-acccount-091523.jpg",
    "@context": "https://schema.org",
    headline: "What's Needed to Open a Business Bank Account in California",
    description:
      "Learn what's needed to open a business bank account in California including key documents needed. Open a California business bank account today with WaFd Bank."
  }
};

const BeforeStartingOwnBusinessBlog = () => {
  const cards = [
    "/blog/small-business/choosing-location-for-business",
    "/blog/small-business/small-business-cybersecurity-tips",
    "/blog/small-business/what-is-the-best-bank-for-a-small-business"
  ];

  const considerContent = (
    <p>
      Small businesses are generally defined as companies with fewer than 500 employees. There are now 7.1 million small
      business employees powering California companies. WaFd Bank is here to help business continue to grow and boost
      the local economy, such as creating jobs. WaFd Bank is a regional bank providing{" "}
      <Link to="/business-banking/small-business" id="blog-small-businesses-link">
        business banking solutions
      </Link>{" "}
      so you can easily manage finances and focus on growing your business. A variety of services and tools we offer can
      help you get your business off the ground (or to the next level) including:
    </p>
  );

  const stateData = {
    introText:
      "The Golden state continues to be a popular place to live and work. California boasts 4.2 million small businesses, which make up 99.8% of all businesses in the state. If you've started your own business or are thinking about it, know that it's important to keep your own business funds separate from your personal finances helps you stay organized and focus on your business priorities, and as an added bonus helps to protect your personal assets too. So, what exactly do you need to know? Read on!",
    image: (
      <StaticImage
        src="../../../images/blogs/small-business/state-business-bank-account-requirements/thumbnail-business-account-california-082724.jpg"
        alt="Flying view of California."
        placeholder="blurred"
        quality="100"
      />
    ),
    considerContent
  };

  return (
    <BlogWrapper
      cards={cards}
      title={"What Is Needed to Open a Business Bank Account in California"}
      pageUrl={"/blog/small-business/california-business-bank-account-requirements"}
      eightColumnsTemplate
      ogImage={"https://www.wafdbank.com/images/adsearch/blogs/og-buisness-account-california-082724.jpg"}
      contactInfo={"LocalBranch"}
      seoData={SEO}
    >
      <StateBusinessBankBlogContent {...stateData} />
    </BlogWrapper>
  );
};

export default BeforeStartingOwnBusinessBlog;
